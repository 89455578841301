/*Filename - App.css*/
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


/* #467097 Cor Azul ELETEC */
/* #f1a238 Cor Laranja ELETEC */
/* rgb(255, 166, 0) Laranja Escuro */
/* #0b1622; Azul Escuro */
/* #1c344c Azul Meio*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

/* .flex{
  display: flex;
}
.flex__center{
  display: flex;
  align-items: center;
  justify-content: center;
} */


/* Botao */

.myButton {
  background-color: #006b1d;
  border-radius: 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
  box-shadow: 1px 1px 3px black;
}

.myButton:hover {
  background-color: #006b1d;
}

.myButton:active {
  position: relative;
  top: 1px;
}


/* Fonts */

.publicsans-thin {
  font-family: "Public Sans", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.publicsans-light {
  font-family: "Public Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.publicsans-regular {
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.publicsans-medium {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.publicsans-bold {
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.publicsans-black {
  font-family: "Public Sans", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.publicsans-thin-italic {
  font-family: "Public Sans", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.publicsans-light-italic {
  font-family: "Public Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.publicsans-regular-italic {
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.publicsans-medium-italic {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.publicsans-bold-italic {
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.publicsans-black-italic {
  font-family: "Public Sans", sans-serif;
  font-weight: 900;
  font-style: italic;
}