@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.section2_all {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #e38d00;
    gap: 40px;
    padding-bottom: 40px;
}

.tituloManu {
    padding: 20px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 80px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.45);
}

.coisasManu {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 70%;
    gap: 20px;
}

.descManu {
    width: 50%;
}

.descManu h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    padding: 5px;
}

.descManu2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* width: 100%; */
    overflow: hidden;
    padding: 10px;
}

.descManu2 h1 {
    padding: 20px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.45);
    text-align: center;
}

.linhaVertical {
    height: 200px;
    border-left: 2px solid #0000007c;
}

.imgDescManu {
    max-width: 100%;
    height: auto;
    display: block;
}


@media screen and (max-width: 768px) {
    .coisasManu {
        width: 90%;
    }

}

@media screen and (max-width: 425px) {
    .section2_all {
        gap: 0;
    }

    .tituloManu {
        font-size: 55px;
    }

    .coisasManu {
        flex-direction: column;
        width: 100%;
        gap: 0;
    }

    .descManu {
        width: 100%;
        padding: 10px;
    }

    .linhaVertical {
        display: none;
    }

    .descManu2 h1 {
        font-size: 4vh;
    }

}

@media screen and (max-width: 375px) {
    .tituloManu {
        font-size: 45px;
    }

}


@media screen and (max-width: 320px) {

    .tituloManu {
        font-size: 42px;

    }

    .descManu2 {
        padding: 0 15px;
    }
}