.navbar__main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 10px;
    position: fixed;
    gap: 12px;
    width: 100%;
    box-shadow: 0px 7px 28px 0px rgba(0,0,0,0.42);
    z-index: 2;

}




.navbar__brand img {
    width: 250px;
}


.navbar button {
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    background-color: transparent;
    border: none;
    font-weight: 300;
}

.navbar button:hover {
    color: rgb(255, 187, 0);
    cursor: pointer;

}

.wpp__number {
    display: flex;
    align-items: center;

}

.wpp__number p {

    font-size: 18px;
}

.wpp__img {
    width: 40px;
    height: 40px;
    padding: 5px;
}

.menuBotao{
    display: none;
}

.menuBotao button {
    all: unset;
    border: none;
    background: transparent;
    box-sizing: border-box;
}

.imgBotao{
    width: 40px;
    height: 40px;
}

.menuMobile {
    display: none;
}



@media screen and (max-width: 768px) {

    .navbar__main{
        display: flex;
        flex-wrap: wrap;
    }
    .menuBotao {
        display: block !important;
    }

    .menuDesktop {
        display: none;
    }

    .wpp__number {
        display: none;
    }

    .menuMobile{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 30px;
    }

    .menuMobile button {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        border: none;
        background: transparent;
        font-size: 26px;
        /* border-bottom: 1px solid rgba(0, 0, 0, 0.267); */
    }
}



@media screen and (max-width: 430px) {

    .navbar__main{
        width: 100%;
    }
    
    .menuDesktop {
        display: none;
    }

    



    .floatWpp {
        display: none;
    }
    
    .wpp2__img {
        display: none;
    }


}

.floatWpp {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 100;
}

.wpp2__img {
    width: 60px;
    z-index: 100;
}

