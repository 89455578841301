.section1__all {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
}



.titulo__principal {
    font-size: 50px;
    font-family: "Montserrat", sans-serif;
    color: #007f8d;
    font-weight: 200;
    padding: 20px 0px 30px 0px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.20);
}


.titulo2 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 10px;
}


.section1__main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    gap: 1rem;
}

.section1_row {
    display: flex;
    flex-direction: row;
    border: #00000005 1px solid;
    border-radius: 40px;
    max-width: 1000px;
    padding: 1rem;
    gap: 2rem;
    transition: 0.3s;
}

.section1__produtos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
}



.section1__produtos__img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    padding: 10px;
    margin: 10px;
}



.titulo__produto {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 38px;
    color: orange;
}

.desc__produto {
    font-size: 18px;
    color: black;
    font-weight: 300;
}

.img__produto {
    object-fit: contain;
    width: 400px;
    max-width: 100%;
    height: 300px;
    display: block;
}

.section1_row:hover {
    box-shadow: 28px 24px 60px 8px rgba(0, 0, 0, 0.39);
}

@media screen and (max-width: 768px) {
    .titulo__principal {
        text-align: center;
    }
}

@media screen and (max-width: 430px) {
    .section1__all {
        width: 100%;
    }

    .titulo__produto {
        width: 100%;
        text-align: center;
    }

    .section1__produtos {
        width: 100%;
    }

    .section1__produtos__img {
        padding: 0 15px;
        width: 100%;
    }

    .section1_row {
        flex-direction: column;
        width: 100%;
    }



}

@media screen and (max-width: 360px) {
    .section1__all {
        max-width: 100%;
    }
    .espacoTitutlo{
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .titulo__principal {
        font-size: 40px;
        font-weight: 500;
        width: 80%;
    }


    .titulo2{
        /* text-align: center; */
        width: 80%;
    }

    .section1__main {
        max-width: 100%;
    }

    .section1__produtos__img {
        padding: 0 15px;
        max-width: 100%;
    }

    .titulo__produto {
        font-size: 33px;
        font-weight: 500;
        text-align: center;
    }

    .section1__produtos {
        max-width: 100%;
    }

    .desc__produto {
        max-width: 100%;
    }

    .section1_row {
        max-width: 100%;
    }
}