@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root{
  --font-base: 'Public Sans', sans-serif; 
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: var(--font-base);
  margin: 0 auto;
  padding: 0;
}

