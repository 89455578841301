@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.section3_all {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #007f8d;
    gap: 40px;
    padding-bottom: 40px;
}

.tituloInst {
    padding: 20px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 80px;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.45);
}

.coisasInst {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 70%;
    gap: 20px;
    color: white;
}

.descInst {
    width: 50%;
}

.descInst h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    padding: 5px;
}

.descInst2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 10px;
}

.descInst2 h1 {
    padding: 20px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.45);
    text-align: center;
}

.linhaVertical {
    height: 200px;
    border-left: 2px solid #0000007c;
}

.imageinst {
    max-width: 100%;
    height: 500px;
    display: block;
}




@media screen and (max-width: 768px) {
    .coisasInst {
        width: 95%;
    }
}

@media screen and (max-width: 425px) {

    .section3_all{
        /* padding: 0; */
        gap: 0;
    }
    .tituloInst {
        font-size: 60px;
        /* padding: 0; */
        /* padding-top: 20px; */
    }

    .coisasInst {
        flex-direction: column;
        /* align-items: start; */
        width: 100%;
        gap: 0;
        padding: 5px;
    }

    .descInst {
        width: 100%;
        padding: 0;
    }

    .descInst2{
        width: 100%;
    }

    .descInst2 h1 {
        font-size: 30px;
    }
    .imageinst{
        height: auto;
    }

}

@media screen and (max-width: 375px) {

    .tituloInst {
        font-size: 60px;
        /* padding: 0; */
    }
    .descInst2{
        width: 100%;
    }
}


@media screen and (max-width: 320px) {
    .tituloInst {
        font-size: 55px;
        /* padding: 0; */
    }

    .descInst2{
        width: 100%;
    }
}