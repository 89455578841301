@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.contatoAll {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #e38d00;
    padding-bottom: 30px;
}

.c1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    /* width: 1000px; */
    gap: 20px;
    padding: 20px;
}

.c1 h1 {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-weight: 700;
}

.c1 h2 {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-weight: 500;
}

.c2 {
    width: 50%;
}

.contatoContainer {
    display: flex;
}

.formularioContainer {
    background-color: white;
    border-radius: 10px;
    width: 650px;
}


.tituloCont {
    padding: 20px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 80px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.45);
}

.form {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    padding: 32px;

}

.formularioContainer h3 {
    padding: 20px;
    font-family: "Montserrat", sans-serif;
    border-bottom: solid thin #ccc;
    font-weight: 500;
    text-align: center;
}

.form label {
    font-family: "Montserrat", sans-serif;
}

.form input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: thin solid #999;
    padding-left: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.instaOsvaldo {
    display: flex;
    align-items: center;
}

.instaOsvaldo img {
    width: 50px;
    margin: 10px;
}

.instaOsvaldo {
    color: white;
    text-decoration: none;
}

textarea {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    border: thin solid #999;
    padding: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.form input[type=submit] {
    align-items: center;
    width: 50%;
}

.form input[type=submit]:hover {
    background-color: #1c344c;
    color: white;
}

.linhaVertical2 {
    height: 80px;
    border-left: 2px solid #0000007c;
}

@media screen and (max-width: 768px) {
    .contatoAll {
        width: 100%;
    }

    .tituloCont{
        font-size: 60px;
    }

    .c1 {
        align-items: start;
        flex-direction: column;
        width: 100%;
    }

    .c2 {
        width: 100%;
    }

    .c2 h2 {
        font-size: 18px;
    }
    
    .linhaVertical2{
        display: none;
    }

    .contatoContainer {
        width: 100%;
    }

    .formularioContainer{
        /* display: none; */
        width: 100%;
    }



    .formularioContainer h3 {
        width: 100%;
    }

    .form {
        /* display: none; */
        width: 100%;
    }

    .form input {
        width: 100%;
    }

}

@media screen and (max-width: 425px) {

    .contatoAll {
        width: 100%;
    }

    .c1 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .contatoContainer {
        width: 100%;
    }
    .formularioContainer{
        width: 100%;
    }

    .form {
        width: 100%;
    }

    .form input {
        width: 100%;
    }
}

@media screen and (max-width: 320px) {
    .tituloCont{
        font-size: 45px;
    }

    .c2 h2 {
        font-size: 16px;
    }
}