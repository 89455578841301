@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.banner__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    overflow-x: hidden;
    
}

/* .banner__main__img {
    display: block;
    height: 60%;
    width: 60%;

}

.banner__main__img2{
    display: none;
} */

.banner__main h1 {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 40px;
    padding: 20px;

}

.banner__second {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    width: 100vw;
    background-color: #272932;

}

.ppa__banner {
    height: 90px;

}


@media screen and (max-width: 430px) {

    .banner__main{
        background-color: #272932;
        padding-top: 60px;
    }
    

    .divCarousel {
        width: 180%;
        padding: 0;
        margin: 0;
    }

    .banner__second{
        flex-direction: column;
        align-items: center;
    }

    .banner__second h1{
       font-size: 30px;
       text-align: center;
    }


}