@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.rodapeAll {
    display: flex;
    flex-direction: row;
    /* height: 50px; */
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.rodapeAll h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 20px;
}

.logoRodape {
    width: 100%;
    height: 30px;
}